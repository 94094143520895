// User Time Zone - https://yarnpkg.com/package/jstz
// Install at website root: yarn add jstz
import jstz from 'jstz';

$(document).ready(function(){


	// User Time Zone
	if (jstz) {
		$('#user_time_zone_guess').val(jstz.determine().name());
	}




})
